import size from 'lodash/size';

import Pet from '@/models/Pet';

export default {
  namespaced: true,

  state: {
    pets: [],
  },

  getters: {
    getPets(state) {
      return Object.values(state.pets).map((pet) => new Pet(pet));
    },

    hasOnePet(state) {
      return size(state.pets) === 1;
    },

    hasPets(state) {
      return size(state.pets);
    },
  },

  mutations: {
    pushPet(state, pet) {
      state.pets.push(pet);
    },

    setPets(state, data) {
      state.pets = data;
    },
  },

  actions: {
    async fetchPets({ commit }) {
      await this.$app.$api
        .get('v1/cx/pets')
        .then((response) => commit('setPets', response.data.data));
    },

    async modifyPet(state, payload) {
      const response = await this.$app.$api.patch(`v1/cx/pets/${payload.uuid}`, payload);

      return response.data.data;
    },

    async storePet({ commit }, payload) {
      const response = await this.$app.$api.post('v1/cx/pets', payload);
      const pet = response.data.data;

      commit('pushPet', pet);

      return pet;
    },
  },
};
