export default {
  namespaced: true,
  state: {
    petConcerns: [],
  },
  getters: {
    getPetConcerns(state) {
      return state.petConcerns;
    },
    getGuidedSearchPetConcerns(state) {
      return state.petConcerns.filter((concern) => concern.show_on_guided_search);
    },
    getCollapsedGuidedSearchPetConcerns(state) {
      return state.petConcerns.filter((concern) => concern.is_primary && concern.show_on_guided_search);
    },
  },
  actions: {
    async fetchPetConcerns({ commit }, payload) {
      await this.$app.$api.get('p/pet/concerns', { params: payload })
        .then((response) => commit('setPetConcerns', response.data.data))
        .catch(() => {});
    },
  },
  mutations: {
    setPetConcerns(state, data) {
      // eslint-disable-next-line no-param-reassign
      state.petConcerns = data;
    },
  },
};
